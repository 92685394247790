import React, { ErrorInfo, ReactNode } from 'react';
import { Button, Container, Group, Stack, Text, Title } from '@mantine/core';
import { Link } from 'react-router-dom';

interface ErrorBoundaryProps {
  children?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (!this.props.children || this.state.hasError) {
      return (
        <Container>
          <Stack align="center" mt="xl">
            <Title order={1}>Oops! Looks like a Nat 1.</Title>
            <Text>We&apos;re sorry, but an unexpected error occurred.</Text>
            <Text size="sm" color="dimmed">
              Error: {this.state.error?.message || 'Unknown error'}
            </Text>
            <Group>
              <Button onClick={() => window.location.reload()}>Reload Page</Button>
              <Button component={Link} to="/">
                Go Home
              </Button>
            </Group>
          </Stack>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
