import { useEffect, useState } from 'react';
import { ProfileDTO } from '@binaryhivetech/campaigner-client';
import { useQueryClient } from '@tanstack/react-query';
import { Provider, User } from '@supabase/supabase-js';
import { create } from 'zustand';
import { supabase } from '@/api/supabaseClient';
import { profileApi } from '@/api/apiClient';

interface AuthState {
  isAuthenticated: boolean;
  profile: ProfileDTO | null;
  setAuth: (isAuth: boolean, profile?: ProfileDTO | null) => void;
  clearAuth: () => void;
}

const useAuthStore = create<AuthState>((set) => ({
  isAuthenticated: false,
  profile: null,
  setAuth: (isAuth, profile) =>
    set({
      isAuthenticated: isAuth,
      profile: profile || null,
    }),
  clearAuth: () =>
    set({
      isAuthenticated: false,
      profile: null,
    }),
}));

export function useAuth() {
  const [loading, setLoading] = useState(true);
  const { isAuthenticated, profile, setAuth, clearAuth } = useAuthStore();
  const [user, setUser] = useState<User | undefined>();
  const queryClient = useQueryClient();

  useEffect(() => {
    const checkSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        const profileData = await fetchProfile();
        setAuth(true, profileData);
        setUser(session.user);
      } else {
        clearAuth();
      }
      setLoading(false);
    };
    checkSession();
  }, []);

  const fetchProfile = async (): Promise<ProfileDTO | undefined> => {
    try {
      const { data } = await profileApi.getProfile();
      return data.data;
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
    return undefined;
  };

  const login = async (email: string, password: string) => {
    setLoading(true);
    try {
      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;
      const profileData = await fetchProfile();
      setAuth(true, profileData);
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const signInWithProvider = async (provider: Provider) => {
    setLoading(true);
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider,
        options: {
          redirectTo: `${window.location.origin}/auth/callback`,
        },
      });
      if (error) throw error;
    } catch (error) {
      console.error(`${provider} sign-in error:`, error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const signInWithDiscord = () => signInWithProvider('discord');
  const signInWithGoogle = () => signInWithProvider('google');

  const signup = async (email: string, password: string) => {
    setLoading(true);
    try {
      const { error } = await supabase.auth.signUp({
        email,
        password,
      });
      if (error) throw error;
    } catch (error) {
      console.error('Signup error:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    setLoading(true);
    try {
      await supabase.auth.signOut();
      clearAuth();
      queryClient.clear();
      localStorage.clear();
      sessionStorage.clear();
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    isAuthenticated,
    user,
    profile,
    loading,
    login,
    signInWithDiscord,
    signInWithGoogle,
    signup,
    logout,
  };
}
