import React from 'react';
import { Paper, Skeleton, Stack, ThemeIcon, Title } from '@mantine/core';
import { IconScript } from '@tabler/icons-react';

export function LoadingState() {
  return (
    <Paper p="xl" radius="md">
      <Stack align="center" gap="xl">
        <ThemeIcon size={80} radius="xl" variant="light" color="blue">
          <IconScript size={50} />
        </ThemeIcon>
        <Title order={2}>Deciphering Ancient Scrolls...</Title>
        <Stack w="100%" gap="md">
          {[...Array(8)].map((_, index) => (
            <Skeleton key={index} height={16} radius="xl" />
          ))}
        </Stack>
      </Stack>
    </Paper>
  );
}
