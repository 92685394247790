import { createTheme } from '@mantine/core';

export const theme = createTheme({
  colors: {
    'brand-dark-teal': [
      '#f3f6f7',
      '#e8e9e9',
      '#cbd0d2',
      '#abb8bc',
      '#91a3a9',
      '#7f959d',
      '#758f99',
      '#637b85',
      '#556e77',
      '#43606a',
    ],
    accent: [
      '#EBA487',
      '#EBA487',
      '#EBA487',
      '#EBA487',
      '#EBA487',
      '#EBA487',
      '#EBA487',
      '#EBA487',
      '#EBA487',
      '#EBA487',
    ],
    'brand-teal': [
      '#e3fef8',
      '#d2f8f0',
      '#abecdf',
      '#81e0cd',
      '#5dd7bd',
      '#46d1b4',
      '#36ceaf',
      '#23b799',
      '#0fa387',
      '#008d74',
    ],
    'brand-orange': [
      '#fff1e3',
      '#fee1d1',
      '#f7c2a3',
      '#f2a172',
      '#ec8448',
      '#ea722d',
      '#ea691f',
      '#d05712',
      '#ba4d0d',
      '#a24003',
    ],
    'footnote-text': [
      '#fef7e5',
      '#f4ecd7',
      '#e6d7b3',
      '#d6c18d',
      '#c9ae6b',
      '#c2a255',
      '#be9c4a',
      '#a88739',
      '#957830',
      '#816722',
    ],
    background: [
      '#f5f5f5',
      '#e7e7e7',
      '#cdcdcd',
      '#b2b2b2',
      '#9a9a9a',
      '#8b8b8b',
      '#848484',
      '#717171',
      '#656565',
      '#575757',
    ],
  },
  primaryColor: 'brand-dark-teal',
});
