import React from 'react';
import { Spotlight } from '@mantine/spotlight';
import { IconSearch } from '@tabler/icons-react';
import { rem } from '@mantine/core';
import { useSpotlightStore } from '@/state/spotlightStore';

export function GlobalSpotlight() {
  const actions = useSpotlightStore((state) => state.actions);

  return (
    <Spotlight
      actions={actions}
      nothingFound="Nothing found..."
      highlightQuery
      searchProps={{
        leftSection: (
          <IconSearch
            style={{
              width: rem(20),
              height: rem(20),
            }}
            stroke={1.5}
          />
        ),
        placeholder: 'Search...',
      }}
    />
  );
}
