import '@mantine/core/styles.css';
import '@mantine/spotlight/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/nprogress/styles.css';
import '@mantine/tiptap/styles.css';
import '@mantine/notifications/styles.css';
import 'mantine-datatable/styles.layer.css';
import 'mantine-contextmenu/styles.layer.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';

import { CookiesProvider } from 'react-cookie';
import { NavigationProgress } from '@mantine/nprogress';
import { Notifications } from '@mantine/notifications';
import { Router } from './Router';
import './globals.css';
import { theme } from './theme';
import { GlobalSpotlight } from '@/components/general/global-spotlight/GlobalSpotlight';
import { useErrorStore } from '@/state/errorStore';
import { GlobalErrorDisplay } from '@/components/error/GlobalErrorDisplay';
import { ContextMenuProvider } from 'mantine-contextmenu';

const queryClient = new QueryClient();

export default function App() {
  const { error, clearError } = useErrorStore();

  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <MantineProvider theme={theme} forceColorScheme="dark">
        <Notifications />
        <NavigationProgress />
        <QueryClientProvider client={queryClient}>
          <ContextMenuProvider>
            <ModalsProvider>
              <GlobalSpotlight />
              <GlobalErrorDisplay error={error} onClose={clearError} />
              <Router />
            </ModalsProvider>
          </ContextMenuProvider>
        </QueryClientProvider>
      </MantineProvider>
    </CookiesProvider>
  );
}
