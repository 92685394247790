import { createClient } from '@supabase/supabase-js';
import { supabaseKey, supabaseUrl } from '@/config/environment.config';

export const supabase = createClient(supabaseUrl, supabaseKey);
export const getSupabaseSession = async () => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  return session;
};
