import React from 'react';
import { Notification } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

interface GlobalErrorDisplayProps {
  error: string | null;
  onClose: () => void;
}

export function GlobalErrorDisplay({ error, onClose }: GlobalErrorDisplayProps) {
  if (!error) return null;

  return (
    <Notification
      icon={<IconX size="1.1rem" />}
      color="red"
      title="Error"
      onClose={onClose}
      style={{
        position: 'fixed',
        top: '1rem',
        right: '1rem',
        zIndex: 9999,
      }}
    >
      {error}
    </Notification>
  );
}
