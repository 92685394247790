import { create } from 'zustand';
import { SpotlightActionData } from '@mantine/spotlight';

interface SpotlightState {
  actions: SpotlightActionData[];
  addAction: (action: SpotlightActionData) => void;
  removeAction: (id: string) => void;
  setActions: (actions: SpotlightActionData[]) => void;
}

export const useSpotlightStore = create<SpotlightState>((set) => ({
  actions: [],
  addAction: (action) =>
    set((state) => ({
      actions: [...state.actions, action],
    })),
  removeAction: (id) =>
    set((state) => ({
      actions: state.actions.filter((action) => action.id !== id),
    })),
  setActions: (actions) => set({ actions }),
}));
