import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Loader } from '@mantine/core';
import { useAuth } from '@/hooks/useAuth';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { isAuthenticated, profile, user, loading } = useAuth();
  const location = useLocation();
  useEffect(() => {
    console.log(user);
  }, [user]);
  if (loading) {
    return <Loader size="xl" />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!profile && location.pathname !== '/complete-profile') {
    return <Navigate to="/complete-profile" state={{ from: location }} replace />;
  }

  return <>{children}</>;
}
